import React from 'react';
import styles from './LodingPage.module.css';
import { useTheme } from '../../themes/ThemesContext';
import { useTranslation } from 'react-i18next';


const LoadingPage = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
  <div  className={`${styles['loaderContainer']}  ${theme === 'light' ? styles.light : styles.dark}`} >
    <div className={styles.loader}>
    <img className={styles.lodingImg} src={require('../../assets/images/logo/icon.png')} alt="loading" />
    <h1 className={styles.Elsharq}> {t('loading')}</h1>
    </div>
  </div>

  );
};

export default LoadingPage;
