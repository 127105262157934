import React, { useEffect, useState, useCallback } from 'react';
import styles from './Blog.module.css';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';
import { useTheme } from '../../themes/ThemesContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../api/api';
import Loading from '../../components/Loading/LodingPage';

const Blog = () => {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Using useCallback to memoize functions and avoid re-creating on each render
  const fetchPosts = useCallback(async () => {
    try {
      const languageCode = i18n.language === 'ar' ? 'ar-SA' : 'en-US';
      const response = await api.get(`/items/posts?filter[status][_eq]=published&fields[]=*,post_category.*,translations.*&deep={"translations":{"_filter":{"languages_code":{"_eq":"${languageCode}"}}}}`);

      const postsData = await fetchImages(response.data.data);
      setPosts(postsData);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [i18n.language]);

  const fetchCategories = useCallback(async () => {
    try {
      const languageCode = i18n.language === 'ar' ? 'ar-SA' : 'en-US';
      const response = await api.get(`/items/Post_category?fields[]=*,translations.*&deep={"translations":{"_filter":{"languages_code":{"_eq":"${languageCode}"}}}}`);

      const categoriesData = response.data.data.map(category => {
        const translation = category.translations?.[0];
        return {
          ...category,
          translatedTitle: translation ? translation.title : category.title,
        };
      });

      setCategories(categoriesData);
    } catch (error) {
      handleError(error);
    }
  }, [i18n.language]);

const fetchImages = async (posts) => {
  // Fetch the images for the posts, including the title for the alt attribute
  return Promise.all(posts.map(async post => {
    if (post.image) {
      const response = await api.get(`/files/${post.image}`);
      const imageUrl = `https://admin.newsharq.com/assets/${response.data.data.filename_disk}`;
      const imageAlt = response.data.data.title || "No title available";
      return { ...post, imageUrl, imageAlt };
    }
    return { ...post, imageUrl: null, imageAlt: "No image available" };
  }));
};

 
  const handleError = (error) => {
    // Generic error handler
    if (error.response) {
      setErrorMessage(t('serverErrorMessage', { status: error.response.status }));
    } else if (error.request) {
      setErrorMessage(t('networkErrorMessage'));
    } else {
      setErrorMessage(t('unexpectedErrorMessage'));
    }
  };

  // Handle post filtering by category and search term
  const filteredPosts = posts.filter((post) => {
    const translation = post.translations?.find(tr => tr.languages_code === (i18n.language === 'ar' ? 'ar-SA' : 'en-US'));
    const title = translation?.title || post.title;
    const content = translation?.content || post.content;

    const matchesCategory = selectedCategory ? post.post_category?.id === selectedCategory : true;
    const matchesSearch = (title && title.toLowerCase().includes(searchTerm.toLowerCase())) || 
                          (content && content.toLowerCase().includes(searchTerm.toLowerCase()));

    return matchesCategory && matchesSearch;
  });

  const handleImageClick = (post) => {
    // Navigate to the selected post
    navigate(`/post/${post.slug}`, { state: { post } });
  };

  const resetFilters = () => {
    // Reset category and search filters
    setSelectedCategory(null);
    setSearchTerm('');
  };

  // Fetch posts and categories when the component mounts and when the language changes
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchPosts();
    fetchCategories();
  }, [fetchPosts, fetchCategories]);

  const isRTL = i18n.language === 'ar';

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={` ${styles.all}  ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
          <div className={styles.header}>
            <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
              <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
                <p style={{ background: " rgba(43, 42, 41, 0.15)" }}>{t('headertitle1')}</p>
                <h1>{t('headertitle2')}</h1>
                <p className={styles['subtitle2']}>{t('headerDescription')}</p>
              </div>
            </MDBContainer>
          </div>

          {errorMessage && (
            <div className={styles.error}>
              <p>{errorMessage}</p>
            </div>
          )}

          <MDBContainer className={`text-center ${styles['popular-topics']} ${isRTL ? styles.rtl : ''} ${theme === 'light' ? styles.light : styles.dark}`}>
            <h2>{t('POPLUR')}</h2>
            <div className={`text-center ${styles['category-buttons']} ${theme === 'light' ? styles.light : styles.dark}`}>
              <p
                className={selectedCategory === null ? styles.active : ''}
                onClick={resetFilters}
                style={selectedCategory === null ? { color: '#f0f0f0' } : {}}
              >
                {t('allCategories')}
              </p>
              {categories.map((category) => (
                <p
                  key={category.id}
                  className={selectedCategory === category.id ? styles.active : ''}
                  onClick={() => setSelectedCategory(category.id)}
                  style={selectedCategory === category.id ? { color: theme === 'light' ? '#00223b' : '#f0f0f0' } : {}}
                >
                  {category.translatedTitle}
                </p>
              ))}
            </div>

            <MDBRow className={styles.posts}>
              {filteredPosts.length > 0 ? (
                filteredPosts.map((post) => {
                  const translation = post.translations?.find(tr => tr.languages_code === (i18n.language === 'ar' ? 'ar-SA' : 'en-US'));
                  const title = translation?.title || post.title;
                  const summary = translation?.summary || post.summary;
                  return (
                    <MDBCol md="3" key={post.id} className="mb-4">
                      <MDBCard className={styles['post-card']} onClick={() => handleImageClick(post)}>
                        <div className={styles['imageContainer']}>
                          {post.imageUrl ? (
                            <MDBCardImage
                              src={post.imageUrl}
                              alt={post.imageAlt}

                              className={styles['cardImage']}
                            />
                          ) : (
                            <div className={styles['imagePlaceholder']}>
                              No Image Available
                            </div>
                          )}
<p
  pill="true"
  style={{
    backgroundColor: categories.find(category => category.id === post.post_category?.id)?.color // Fallback to gray if no color
  }}
  className={styles['categoryBadge']}
>
  {categories.find(category => category.id === post.post_category?.id)?.translatedTitle || 'Uncategorized'}
</p>


                          
                        </div>

                        <MDBCardBody
                          className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark} ${i18n.language === 'ar' ? styles['font-almarai'] : ''}`}>
                          <MDBCardText className={`${styles['post-date']} ${i18n.language === 'ar' ? styles.rtlfordate : ''}`}>
                            {new Date(post.date_published).toLocaleDateString()}
                          </MDBCardText>
                          <MDBCardTitle className={`${styles['post-title']} ${i18n.language === 'ar' ? styles.rtlfordate : ''}`}>
                            {title}
                          </MDBCardTitle>
                          <MDBCardText className={` ${styles['post-summary']} ${i18n.language === 'ar' ? styles.rtlfordate : ''}`}>
                            {summary}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  );
                })
              ) : (
                <p className={`${styles['No-Posts']} ${theme === 'light' ? styles.light : styles.dark}`}>{t('noPostsMessage')}</p>
              )}
            </MDBRow>
          </MDBContainer>
        </div>
      )}
    </>
  );
};

export default Blog;
