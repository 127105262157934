import React from 'react';
import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={`container col-md-8 ${styles.containnner}`}>
      <div className={`row  ${styles.row}`} >
        <div className={`four0four col-md-4 col-sm-12 offset-md-2 ${styles.four0four}`}>
          <span className={styles.off}>4</span>
          <span className={styles.flicker2}>0</span>
          <span className={styles.flicker3}>4</span>
        </div>
        <div className={`notFound col-lg-2 col-md-12 ${styles.notFound}`}>
          <span className={styles.flicker4}>P</span>
          <span className={styles.off}>a</span>
          <span>ge </span>
          <span>not </span>
          <span className={styles.flicker3}>f</span>
          <span className={styles.flicker1}>o</span>
          <span className={styles.flicker3}>u</span>
          <span>n</span>
          <span className={styles.flicker3}>d</span>
        </div>
        {/* <div className={styles.fog0}></div>
        <div className={styles.fog1}></div>
        <div className={styles.fog2}></div>
        <div className={styles.fog3}></div>
        <div className={styles.fog4}></div>
        <div className={styles.fog5}></div>
        <div className={styles.fog6}></div>
        <div className={styles.fog7}></div>
        <div className={styles.fog8}></div>
        <div className={styles.fog9}></div> */}
      </div>
    </div>
  );
};

export default NotFound;
