import React, { useState, useEffect } from 'react';
import styles from './Back.module.css';

const Back = () => {
  const [show, setShow] = useState(false);
  const [running, setRunning] = useState(false);
  const [positionedAtTop, setPositionedAtTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShow(true);
      setPositionedAtTop(false);
    } else {
      setShow(false);
    }
  };

  const scrollToTop = () => {
    setRunning(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Reset the running state after the transition and keep the car at the top
    setTimeout(() => {
      setRunning(false);
      setPositionedAtTop(true);
    }, 1000); // Match this duration to your CSS transition time
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div 
      className={`${styles.carTop} ${styles.default} ${show ? styles.show : ''} ${running ? styles.running : ''} ${positionedAtTop ? styles.hidden : ''}`} 
      onClick={scrollToTop}
    >
      <span>
        <img 
          src={require('../../assets/images/LandingPage/car.png')} 
          alt="Top" 
          title="Back to top" 
          width="74" 
          height="114" 
        />
      </span>
    </div>
  );
};

export default Back;
