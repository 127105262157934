import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom'; // Removed 'Router'
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog/Blog';
import PostDetail from './Pages/Blog/PostDetail';
import './i18n';
import './index.css';
import './App.css';
import Footer from './components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Header/Navbar';
import Back from './components/BackToTop/Back';
import NotFound from './components/NotFound/NotFound';
import Products from './Pages/OurServices/Products';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    if (selectedLanguage === 'ar') {
      document.body.classList.add('arabic-font');
    } else {
      document.body.classList.remove('arabic-font');
    }
  }, [i18n.language]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]); // Added i18n as a dependency

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Navbar changeLanguage={changeLanguage} />
      <div className="App main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:slug" element={<Products />} />

          <Route path="/blog" element={<Blog />} />
          <Route path="/post/:slug" element={<PostDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <Back />
      </div>
    </>
  );
};

export default App;
