
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';  
import styles from './PostDetail.module.css';
import { useParams } from 'react-router-dom';
import api from '../../api/api';
import { useTheme } from '../../themes/ThemesContext';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { FacebookShare, TwitterShare, LinkedinShare } from 'react-share-kit';
import Loading from '../../components/Loading/LodingPage';

const PostDetail = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const { slug } = useParams();

  const [post, setPost] = useState(null);
  const [translationError, setTranslationError] = useState(false);
  const [files, setFiles] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchPostData();
  }, [slug, i18n.language]);



  const fetchPostData = async () => {
    try {
      const languageCode = i18n.language === 'ar' ? 'ar-SA' : 'en-US';
      const response = await api.get(
        `/items/posts?filter[slug][_eq]=${slug}&filter[status][_eq]=published&fields[]=*,seo.*,translations.*,image.*`
      );

      const postData = response.data.data[0];

      if (postData) {
        const translation = postData.translations.find(translation => translation.languages_code === languageCode);
        if (translation) {
          postData.title = translation.title;
          postData.content = translation.content;
          postData.subtitle = translation.subtitle;
          postData.summary = translation.summary;
          setTranslationError(false);
        } else {
          setTranslationError(true);
        }

        if (postData.image) {
          setFiles([{ directus_files_id: postData.image.filename_disk }]);
        } else {
          const firstImage = extractFirstImageFromContent(postData.content);
          if (firstImage) {
            setFiles([{ directus_files_id: firstImage.split('/').pop() }]); 
          }
        }

        setPost(postData);
        setLoading(false);

      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const extractFirstImageFromContent = (content) => {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    const imgTag = doc.querySelector('img');
    return imgTag ? imgTag.src : null;
  };

 



  




  const getFontFamily = () => {
    return i18n.language === 'ar' ? 'Almarai' : 'Poppins';
  };

  const sanitizeAndStripHtml = (htmlContent) => {
    const sanitizedContent = DOMPurify.sanitize(htmlContent);
    return sanitizedContent.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
  };

  const sanitizedContent = post ? DOMPurify.sanitize(`${post.content}`) : '';
  const isRTL = i18n.language === 'ar';

  const currentUrl = window.location.href;
  const titleToShare = post ? `${post.title}` : '';
  const descriptionToShare = post ? 
  (sanitizeAndStripHtml(post.summary || post.title).substring(0, 95) + '...') : '';

  const handleCopyClick = () => {
    navigator.clipboard.writeText(currentUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? 'rtl' : ''}`}>
      {loading ? (
        <Loading />
      ) : (
        <>
{post && (
  <Helmet>
    {/* Primary Meta Tags */}
    <title>{post.seo?.meta_title || post.title}</title>
    <meta name="description" content={post.seo?.meta_description || descriptionToShare} />
    <meta name="keywords" content={post.seo?.meta_keywords?.join(', ') || ''} />

    {/* Open Graph / Facebook */}
    <meta property="og:title" content={post.seo?.og_title || post.title} />
    <meta property="og:description" content={post.seo?.og_description || descriptionToShare} />
    <meta property="og:image" content={`https://admin.newsharq.com/assets/${post.seo?.og_image || files[0]?.directus_files_id}`} />
    <meta property="og:url" content={currentUrl} />
    <meta property="og:type" content={post.seo?.og_type|| post.og_type} />


    {/* Twitter */}
    <meta name="twitter:card" content={post.seo?.twitter_card || 'summary_large_image'} />
    <meta name="twitter:title" content={post.seo?.meta_title || post.title} />
    <meta name="twitter:description" content={post.seo?.meta_description || descriptionToShare} />
    <meta name="twitter:image" content={`https://admin.newsharq.com/assets/${post.seo?.og_image || files[0]?.directus_files_id}`} />
  
  {/* Canonical URL */}
              <link rel="canonical" href={currentUrl} />
  </Helmet>
)}


        <div className={styles.header}>
            <MDBContainer className={`${styles.contain} ${isRTL ? styles.containRtl : ''}`}>
              {post ? (
                <div className={`${styles['header-content']} ${isRTL ? styles['header-contentRtl'] : ''}`}>
                  <h1 style={{ fontFamily: getFontFamily() }} className={styles['post-title']}>{post.title}</h1>
                  <p style={{ fontFamily: getFontFamily() }}>{new Date(post.date_published).toLocaleDateString()}</p>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </MDBContainer>
          </div>

          <MDBContainer className={`${styles.postContainer} ${isRTL ? styles.containRtl : ''}`}>
            {translationError ? (
              <div style={{ fontFamily: getFontFamily() }} className={`${styles['noTranslation']} ${theme === 'light' ? styles.light : styles.dark}`}>
                {t('noTranslation')}
              </div>
            ) : post ? (
              <div style={{ fontFamily: getFontFamily() }} className={`${styles['post-card']} ${theme === 'light' ? styles.light : styles.dark} ${isRTL ? styles.rtl : ''}`}>
                <MDBRow className={`${styles['post-content']} ${theme === 'light' ? styles.light : styles.dark}`}>
                  <MDBCol md="12">
                    <div className={styles['post-text']} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                  </MDBCol>
                </MDBRow>

                <MDBContainer className={styles.shareContainer}>
                  <div className={styles.shareText}>{t('shareOnSocial')}</div>
                  <div className={styles.urlContainer} onClick={handleCopyClick}>
                    <div className={styles.urlInput}>
                      {isCopied ? t('Copied') : t('copylink')}
                    </div>
                    <MDBIcon fas icon="link" className={styles.copyButton} />
                  </div>
                  <div className={styles.socialButtons}>
                    <FacebookShare url={currentUrl} quote={titleToShare} borderRadius={7} className={styles.facebookButton} />
                    <TwitterShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.twitterButton} />
                    <LinkedinShare url={currentUrl} title={titleToShare} borderRadius={7} className={styles.linkedinButton} />
                  </div>
                </MDBContainer>
              </div>
            ) : (
              <p>Loading...</p>
            )}

         
          </MDBContainer>
        </>
      )}
    </div>
  );
};

export default PostDetail;

