
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalTitle
} from 'mdb-react-ui-kit';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Products.module.css';
import { useTheme } from '../../themes/ThemesContext';
import Loading from '../../components/Loading/LodingPage';

function Products() {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const { slug } = useParams();
  const navigate = useNavigate();
  
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [centredModal, setCentredModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // State for selected image in modal

  const isRTL = i18n.language === 'ar';

  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const languageCode = i18n.language === 'ar' ? 'ar-SA' : 'en-US';
        const response = await fetch(
          `https://admin.newsharq.com/items/products?filter[status][_eq]=published&fields[]=*,Product_image.*,translations.*,gallery.*&deep={"translations":{"_filter":{"languages_code":{"_eq":"${languageCode}"}}}}`
        );
        const data = await response.json();

        const productsData = data.data.map((product) => {
          const translation = product.translations?.[0];
          return {
            ...product,
            translatedName: translation ? translation.name : product.name,
            price: translation ? translation.price : null,
            description: translation ? translation.description : t('productsSection.description'),
            imageUrl: `https://admin.newsharq.com/assets/${product.Product_image.filename_disk}`,
            imageAlt: product.Product_image?.title || product.translatedName, // Main image alt
            slug: product.slug,
            subtitle: translation ? translation.sup_title : '', // Subtitle
            gallery: Array.isArray(product.gallery) ? product.gallery.map((image) => ({
              id: image.id,
              url: `https://admin.newsharq.com/assets/${image.directus_files_id}`,
            })) : [], // Map gallery images or provide an empty array
          };
        });
        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [i18n.language, t]);

  // Handle product click: open modal and update URL
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setSelectedImageUrl(product.imageUrl); // Default to main image
    setCentredModal(true);
    navigate(`/products/${product.slug}`);
  };

  // Handle closing modal: reset URL
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setCentredModal(false);
    navigate('/products');
  };

  // Check if there's a product slug in the URL and open the modal if necessary
  useEffect(() => {
    if (slug) {
      const product = products.find((p) => p.slug === slug);
      if (product) {
        setSelectedProduct(product);
        setSelectedImageUrl(product.imageUrl); // Set default image when modal opens
        setCentredModal(true);
      }
    } else {
      setCentredModal(false);
    }
  }, [slug, products]);

  // Function to render dynamic meta tags for each product
  const renderMetaTags = () => {
    if (!selectedProduct) return null;

    return (
      <Helmet>
        <title>{`${selectedProduct.translatedName} | Al Sharq Products`}</title>
        <meta
          name="description"
          content={selectedProduct.description || 'Explore Al Sharq products.'}
        />
        <meta name="keywords" content={`sanitary equipment, fire equipment, ${selectedProduct.translatedName}`} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={selectedProduct.translatedName} />
        <meta property="og:description" content={selectedProduct.description} />
        <meta property="og:image" content={selectedProduct.imageUrl} />
        <meta property="og:url" content={`https://www.newsharq.com/products/${selectedProduct.slug}`} />
        <meta property="og:type" content="product" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={selectedProduct.translatedName} />
        <meta name="twitter:description" content={selectedProduct.description} />
        <meta name="twitter:image" content={selectedProduct.imageUrl} />

        {/* Canonical URL */}
        <link rel="canonical" href={`https://www.newsharq.com/products/${selectedProduct.slug}`} />
      </Helmet>
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`text-center ${styles.all} ${theme === 'light' ? styles.light : styles.dark}`}>
          {/* Render dynamic meta tags */}
          {renderMetaTags()}

          <section className={`${styles.productsSection}`}>
            <MDBContainer className={`${isRTL ? styles.allRtl : ''}`}>
              <MDBRow className="d-flex align-items-center justify-content-center mb-5">
                <MDBCol md="4" sm="12">
                  <img
                    src={require('../../assets/images/LandingPage/china.webp')}
                    alt="Placeholder"
                    className="img-fluid border border-2 border-info rounded my-3"
                  />
                </MDBCol>
                <MDBCol md="8" sm="12">
                  <h1 className={isRTL ? 'text-end' : 'text-start'}>
                    {t('productsSection.title')}
                  </h1>
                  <p className={`fs-5 ${isRTL ? 'text-end' : 'text-start'}`}>
                    {t('productsSection.content')}
                  </p>
                </MDBCol>
              </MDBRow>

              {['type1', 'type2', 'type3'].map((type) => {
                const productsOfType = products.filter((product) => product.type === type);

                if (productsOfType.length === 0) {
                  return null;
                }

                return (
                  <div key={type} className="mb-5">
                    <h1 className="mb-5">
                      {t(`types.${type}`)}
                      <hr className="m-auto mt-3" style={{ width: '50px' }} />
                    </h1>
                    <MDBRow>
                      {productsOfType.map((product) => (
                        <MDBCol key={product.id} md="3" sm="6" className="mb-4">
                          <MDBCard
                            className={`${styles.productCard} ${styles.hoverCard}`}
                            onClick={() => handleProductClick(product)}
                          >
                            {product.price && (
                              <div className={styles.priceTag}>{`${product.price} LE`}</div>
                            )}
                            <div className={styles.imageOverlay}>
                              <MDBCardImage
                                src={product.imageUrl}
                                alt={product.translatedName} // Remove redundant 'image' from alt
                                position="top"
                                className={styles.productImage}
                              />
                              {/* Add both the subtitle and description in the overlay */}
                              <div className={styles.overlayContent}>
                                {product.subtitle && <p className="text-white">{product.subtitle}</p>} {/* Subtitle */}
                              </div>
                            </div>
                            <MDBCardBody style={{ background: 'transparent' }} className={styles.productCardBody}>
                              <h2 className="card-title fs-5">{product.translatedName}</h2>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </div>
                );
              })}
            </MDBContainer>
          </section>

          {/* Product Modal */}
          {selectedProduct && (
  <MDBModal className='mt-5' tabIndex="-1" open={centredModal} onClose={handleCloseModal}>
    <MDBModalDialog centered size='lg' className='p-3 p-xl-5 my-5'>
      <MDBModalContent>
        <MDBModalHeader>
          <MDBModalTitle>{selectedProduct.translatedName}</MDBModalTitle>
          <MDBBtn className="btn-close" color="danger" onClick={handleCloseModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
          <div className={`${styles.modalLayout}`}>
            {/* Main image */}
            <div className={`${styles.mainImageWrapper}`}>
              <img
                src={selectedImageUrl}
                alt={selectedProduct.translatedName}
                className={`img-fluid ${styles.mainImage}`}
              />
            </div>

            {/* Gallery images */}
            <div className={`${styles.galleryWrapper}`}>
              {selectedProduct.gallery.map((image) => (
                <div key={image.id} className={`${styles.thumbnailWrapper}`}>
                  <img
                    src={image.url}
                    alt={selectedProduct.translatedName} // Descriptive alt text
                    className={`${styles.galleryImage}`}
                    onClick={() => setSelectedImageUrl(image.url)} // Set selected image
                    style={{ cursor: 'pointer', border: selectedImageUrl === image.url ? '2px solid #000' : 'none' }} // Highlight selected image
                  />
                </div>
              ))}
            </div>
          </div>
          <p style={{fontSize:"12px"}} className="text-dark mt-4" >{selectedProduct.description}</p>
        </MDBModalBody>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>
)}

        </div>
      )}
    </>
  );
}

export default Products;
